import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'src/app/base/base.component';
import { AnagraficaService } from 'src/app/services/anagraphic.service';
import { ClasseService } from 'src/app/services/class.service';
import { PermissionRoleService } from 'src/app/services/permissionRole.service';
import { checkUserCategory, isAdminOrStaff } from 'src/app/utilities/users-utilities';
import { password_regex } from "../../utilities/regex";
import { confirmPasswordValidator } from "../../utilities/validators/confirm-password-validator";
import * as moment from "moment";
import { getCategories, getChannels, getSidebarItems, getSubscriptions } from "../../utilities/constants";
import { checkFormFields, checkIncludedFields, dateValidator, formatDateField } from "../../utilities/form-utilities";

@Component({
  selector: 'app-anagrafica',
  templateUrl: './anagrafica.component.html',
  styleUrls: ['./anagrafica.component.scss']
})
export class AnagraficaComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild('closeModalButton') closeModalButton: ElementRef;

  adminOrStaff = isAdminOrStaff();
  data: any;
  members: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  records: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  deleteContent: any;
  role = null;
  currentPage = 0;
  pageSize = 10;
  pageEvent: PageEvent;
  user_id: string;
  displayedColumns: string[] = ['data_iscrizione', 'nome', 'cognome', 'data_nascita', 'email', 'ruolo', 'azione'];
  genitoreDisplayedColumns: string[] = ['nome', 'cognome', 'phone', 'email', 'azione'];
  userForm: FormGroup = this.initUserForm();
  filtersForm: FormGroup = this.initFiltersForm();
  dateForm: FormGroup = this.initFiltersDate();
  minEndDate: any;
  today: any;
  roles = [];
  languages = [];
  levels = [];
  url_role_id = null;
  sidebar_items = getSidebarItems();
  channels = getChannels();
  subscriptions = getSubscriptions();
  categories = getCategories();
  order_date: 'asc' | 'desc' = 'desc';
  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly _anagraficaService: AnagraficaService,
    private readonly _roleService: PermissionRoleService,
    private readonly classService: ClasseService,
    private readonly router: Router,
    private readonly toastr: ToastrService,
    private readonly dateAdapter: DateAdapter<Date>
  ) {
    super();
    this.dateAdapter.setLocale('it-IT');
  }

  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.user_id = localStorage.getItem('user_id');

    //se ci troviamo nelle specifiche sezioni dobbiamo filtrare i risultati
    this.url_role_id = this.router.url.split("/")[2] ?? null;

    this.getUsers();
    this.getRoles();
    this.checkFormFields();
    this.getLanguages();
    this.getLevel();

    this.filtersForm.valueChanges.pipe(debounceTime(1000)).subscribe({
      next: () => {
        this.currentPage = 0;
        this.pageSize = 10;
        this.getUsers()
      }
    })

    //controllo dei campi obbligatori al cambio del ruolo
    this.userForm.get('role').valueChanges.subscribe({
      next: () => {
        this.checkFormFields();
      }
    })

    this.userForm.get('birth_date').valueChanges.subscribe({
      next: value => {
        if (checkIncludedFields(this.userForm, 'role', [5, 6])) {
          this.userForm.get('category').setValue(checkUserCategory(value))
        }
      }
    })

    this.dateForm.get('data_inizio').valueChanges.subscribe({
      next: value => {
        this.minEndDate = value;
      }
    });


  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Inizializzazione del form per la creazione di un utente
   * @private
   */
  private initUserForm() {
    return new FormGroup({
      name: new FormControl(null, Validators.required),
      surname: new FormControl(null, Validators.required),
      birth_date: new FormControl('', [dateValidator]),
      email: new FormControl(null),
      iscritto: new FormControl(null),
      registration_date: new FormControl(null),
      password: new FormControl(null),
      password_confirmation: new FormControl(null),
      phone: new FormControl(null),
      address: new FormControl(null),
      city: new FormControl(null),
      cap: new FormControl(null),
      color_bg: new FormControl('#000000'),
      fiscal_code: new FormControl(null),
      pec: new FormControl(null),
      channel: new FormControl(null),
      p_iva: new FormControl(null),
      unique_code: new FormControl(null),
      role: new FormControl(this.url_role_id ?? 3, Validators.required),
      social: new FormControl(null),
      privacy: new FormControl(null),
      email_promozionali: new FormControl(null),
      consenso_immagini: new FormControl(null),
      search_student: new FormControl(null),
      category: new FormControl(null)
    }, { validators: [confirmPasswordValidator] });
  }

  /**
   * Inizializzazione del form per il filtraggio della tabella
   * @private
   */
  private initFiltersForm() {
    return new FormGroup({
      search: new FormControl(null),
      age: new FormControl(null),
      subscribed: new FormControl(null),
      language: new FormControl(null),
      level: new FormControl(null)
    });
  }

  /**
   * Inizializzazione del form per il filtraggio delle date
   * @private
   */
  private initFiltersDate() {
    return new FormGroup({
      data_inizio: new FormControl(null),
      data_fine: new FormControl(null),
    });
  }

  /**
   * Impostazione data inizio
   */
  onStartDateChange(): void {
    const startDate = this.dateForm.get('data_inizio').value;
    if (startDate) {
      this.minEndDate = startDate;
    }
  }

  /**
   * Formattazione date
   * @param date 
   * @returns 
   */
  formatDate(date) {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  /**
   * Reset delle date
   */
  resetDate() {
    this.dateForm.patchValue({ data_inizio: null });
    this.dateForm.patchValue({ data_fine: null });
    this.minEndDate = null;
    this.getUsers()
  }

  /**
   * Set delle date
   */
  setDate() {
    this.dateForm.patchValue({
      data_inizio: this.getFormattedDate(this.dateForm.value.data_inizio, 'YYYY-MM-DD'),
      data_fine: this.getFormattedDate(this.dateForm.value.data_fine, 'YYYY-MM-DD')
    });
    if (this.dateForm.value.data_inizio > this.dateForm.value.data_fine) {
      this.toastr.error('Data fine antecedente alla data di inizio');
    } else {
      this.getUsers();
    }
  }

  /**
   * Ritorna la lista dei ruoli
   * @private
   */
  private getRoles() {
    this._roleService.getRoles().pipe(takeUntil(this.destroy$)).subscribe({
      next: response => {
        this.roles = response.result.splice(1, 6).map(item => ({
          label: item.display_name,
          value: item.id
        }));
      }
    })
  }

  /**
   * Ritorna la lista dei livelli
   * @private
   */
  private getLevel() {
    this.classService.getLevel().pipe(takeUntil(this.destroy$)).subscribe({
      next: response => {
        this.levels = response.result.map(item => ({
          label: item.nome,
          value: item.nome
        }));
      }
    })
  }

  /**
   * Converte la data
   * @param date 
   * @param format 
   * @returns 
   */
  getFormattedDate(date: moment.MomentInput, format: string | undefined) {
    return moment(date).locale('it').format(format);
  }

  /**
   * Ritorna la lista delle lingue
   * @private
   */
  private getLanguages() {
    this.classService.getLanguage().pipe(takeUntil(this.destroy$)).subscribe({
      next: response => {
        this.languages = response.result.map(item => ({
          label: item.nome_lingua,
          value: item.nome_lingua
        }));
      }
    })
  }

  /**
   * Applica i validatori ai FormControl
   * @private
   */
  private checkFormFields() {
    let required_fields = [];
    let unrequired_fields = [];
    let reset_fields = [];

    if (checkIncludedFields(this.userForm, 'role', [2, 3, 4])) {
      required_fields = required_fields.concat(['email', 'password_confirmation']);
      this.userForm.get('password').setValidators([Validators.required, Validators.pattern(password_regex())]);
      this.userForm.get('password').updateValueAndValidity();
    }
    else {
      unrequired_fields = unrequired_fields.concat(['email', 'password_confirmation', 'password', 'color_bg']);
      reset_fields = reset_fields.concat(['email', 'password_confirmation', 'password', 'color_bg']);
    }

    if (checkIncludedFields(this.userForm, 'role', [5, 6], true)) {
      reset_fields = reset_fields.concat(['address', 'pec', 'city', 'cap', 'fiscal_code', 'p_iva', 'unique_code']);
    }

    if (checkIncludedFields(this.userForm, 'role', [5], true)) {
      reset_fields = reset_fields.concat(['iscritto', 'registration_date', 'category']);
    }

    if (checkIncludedFields(this.userForm, 'role', [6], true)) {
      reset_fields = reset_fields.concat(['search_student']);
    }

    if (checkIncludedFields(this.userForm, 'role', [7])) {
      unrequired_fields = unrequired_fields.concat(['surname']);
      reset_fields = reset_fields.concat(['surname']);
    }
    else {
      required_fields = required_fields.concat(['surname']);
    }

    checkFormFields(this.userForm, required_fields, unrequired_fields, reset_fields);
  }

  /**
   * Funzione che aggiunge la voce Tutti nell'elenco di una select
   * @param array
   * @private
   */
  getSelectWithAllField(array: Array<any>) {
    return [{ value: null, label: 'Tutti' }, ...array];
  }

  /**
   * Ritorna la stringa della sezione in cui ci troviamo
   */
  getSelectedElement() {
    return this.sidebar_items.find(item => item.value == this.url_role_id)?.label.toLowerCase();
  }

  /**
   * All'apertura della modale assegna il valore corretto alla select del ruolo
   */
  openModal() {
    if (this.url_role_id) {
      this.userForm.patchValue({ role: this.url_role_id });
    }
  }

  /**
   * Ritorna la lista degli utenti
   */
  getUsers() {
    this._anagraficaService.getUsers(this.getFilters()).pipe(takeUntil(this.destroy$)).subscribe({
      next: response => {
        this.members.next(response.result);
        this.data = new MatTableDataSource(response.result);
        this.records.next(response.count);
      }
    })
  }

  /**
   * Ritorna i filtri
   * @private
   */
  private getFilters() {
    return {
      ...this.filtersForm.value,
      role: this.url_role_id,
      start: this.currentPage * this.pageSize,
      length: this.pageSize,
      sort_direction: this.order_date,
      sort_active: 'created_at',
      data_inizio: this.dateForm.value.data_inizio,
      data_fine: this.dateForm.value.data_fine
    }
  }

  /**
   * Creazione di una nuova utenza
   */
  addUser() {
    formatDateField(this.userForm, ['registration_date']);

    const formValue = {
      ...this.userForm.value,
      name: this.userForm.get('name').value.toUpperCase(),
      surname: this.userForm.get('surname').value ? this.userForm.get('surname').value.toUpperCase() : null,
      address: this.userForm.get('address').value ? this.userForm.get('address').value.toUpperCase() : null,
      city: this.userForm.get('city').value ? this.userForm.get('city').value.toUpperCase() : null,
      fiscal_code: this.userForm.get('fiscal_code').value ? this.userForm.get('fiscal_code').value.toUpperCase() : null
    };
    this._anagraficaService.addUser(formValue).pipe(takeUntil(this.destroy$)).subscribe({
      next: response => {
        this.closeModalButton.nativeElement.click();
        this.toastr.success(response.message);
        this.goToUser(response.result.id);
      },
      error: response => this.toastr.error(response.result)
    })
  }

  /**
   * Al click su un elemento della sidebar
   * @param role
   */
  filterRole(role: any) {
    this.router.navigate(["/anagrafica", role])
  }

  /**
   * Redirect verso la pagina di dettaglio
   * @param id
   */
  goToUser(id: number) {
    if (this.adminOrStaff) {
      this.router.navigate(['/anagrafica/info-anagrafica', id])
    }
  }

  /**
   * Gestione della paginazione
   * @param e
   */
  handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.getUsers();
    return e;
  }

  /**
   * Gestione del sorting della colonna Data di iscrizione
   * @param column
   */
  toggleOrder(column: string) {
    if (column === 'created_at') {
      this.order_date = this.order_date === 'desc' ? 'asc' : 'desc';
      this.getUsers();
    }
  }

  /**
   * Eliminazione utenza
   * @param id
   */
  deleteMember(id: any) {
    this._anagraficaService.deleteMember(id).pipe(takeUntil(this.destroy$)).subscribe({
      next: response => {
        this.toastr.success(response.message);
        this.getUsers();
      },
      error: response => this.toastr.error(response.result)
    })
  }

  /**
   * Export in Excel
   */
  exportToExcel(): void {
    this._anagraficaService.exportData(this.getFilters()).pipe(takeUntil(this.destroy$)).subscribe(
      (response: Blob) => this.downloadExcel(response),
      error => console.error("Errore durante l'esportazione in Excel:", error)
    );
  }
}

