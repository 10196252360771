<div class="altezza-page px-0 mx-0">
    <div class="row px-0 mx-0" style="height: 100%;">
        <div class="col-md-2 px-0 mx-0" style="position: relative">
            <aside class="bg-color">
                <div class="margine-sup padding-logo">
                    <a routerLink="/appuntamenti">
                        <img alt="Logo" class="h-25px logo" src="/assets/img/logo-yeschool-1.svg" />
                    </a>
                </div>
            </aside>
        </div>
        <div class="col-md-10 margine-sup">
            <div class="section__header">
                <div class="row mb-2 d-flex align-items-center">
                    <div class="col-8">
                        <h1 class="mb-0 pb-0">Lezione di giorno {{giorno|async}}</h1>
                    </div>
                </div>
            </div>
            <div class="section__body">
                <table class="demTable mt-5 mb-2">
                    <tbody>
                        <tr>
                            <th style="display: none;"></th>
                        </tr>
                        <tr>
                            <td>

                                <b *ngIf="(nome|async)!=null">Docente</b>: {{nome|async}} {{cognome|async}}
                            </td>
                            <td></td>
                            <td><b>Numero studenti totali</b>:{{records|async}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="tab-content" id="nav-tabContent">
                <!-- ? TAB STUDENTI  -->
                <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                    <div class="row my-4">
                        <div *ngIf="(records | async) == 0" class="col-4"></div>
                        <div class="col-10"></div>
                        <div class="col-2 d-flex justify-content-end">
                            <button type="button" class="btn color-butt my-2" data-toggle="modal"
                                data-target="#exampleModalStudent">
                                Aggiungi studente
                            </button>
                        </div>
                    </div>

                    <div *ngIf="(records | async) == 0">
                        <div class="no-cont py-4"><i class="f-16">Studenti non ancora aggiunti</i> </div>
                    </div>

                    <table *ngIf="(records | async) > 0" mat-table class="box_super tb-bord"
                        [dataSource]="(students|async)">

                        <ng-container matColumnDef="name_surname">
                            <th mat-header-cell *matHeaderCellDef class="col-3 colore-riga testo-riga">Nome e cognome
                            </th>
                            <td mat-cell *matCellDef="let element" class="cursor-pointer">
                                {{element?.studenti?.[0].name}}
                                {{element?.studenti?.[0].surname}}
                            </td>
                        </ng-container>


                        <ng-container matColumnDef="presenza">
                            <th mat-header-cell *matHeaderCellDef class="col-3 colore-riga testo-riga">Presenza</th>
                            <td mat-cell *matCellDef="let element" class="cursor-pointer">
                                {{element?.presenza_studente}}
                            </td>
                        </ng-container>


                        <ng-container matColumnDef="note_studente">
                            <th mat-header-cell *matHeaderCellDef class="col-3 colore-riga testo-riga">Note</th>
                            <td mat-cell *matCellDef="let element" class="cursor-pointer">{{element?.note_studente}}
                            </td>
                        </ng-container>


                        <ng-container matColumnDef="azione">
                            <th mat-header-cell *matHeaderCellDef class="col-3 colore-riga testo-riga">
                                <span>Azione</span>
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <button class="btn btn-outline-dark" (click)="setEdit(element)" data-toggle="modal"
                                    data-target="#editStudent">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        class="bi bi-pencil-fill" viewBox="0 0 16 16">
                                        <path
                                            d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                                    </svg>
                                </button>
                                <button class="btn btn-outline-danger" data-toggle="modal"
                                    data-target="#modalDeleteClass" (click)="deleteModal(element)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        class="bi bi-trash-fill" viewBox="0 0 16 16">
                                        <path
                                            d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                                    </svg>
                                </button>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>

                    <mat-paginator [length]="(records | async)" [pageSize]="pageSize"
                        [pageSizeOptions]="[5, 10, 25, 100]" (page)="pageEvent = handlePage($event)"
                        showFirstLastButtons>
                    </mat-paginator>
                </div>
            </div>
        </div>



        <!-- ! Modal Aggiungi Studente -->
        <div class="modal fade" id="exampleModalStudent" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title" id="exampleModalLabel">Aggiungi Studente</h1>
                        <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <form [formGroup]="formAddStudente">
                            <label class="ast" for="inputLevel"><b>Studente</b></label>
                            <input type="text" class="form-control form-control-lg mb-3 form-control_modal search"
                                placeholder="Cerca per nome o cognome" (keyup)="searchStudents()"
                                formControlName="search_student">
                            <div class="container" *ngIf="visualizzaStudenti" class="boxautocomplete">
                                <div class="row element-autocomplete mx-0 px-0 cursor-pointer"
                                    *ngFor="let item of (studentis | async)" style="padding:20px;"
                                    (click)="setStudents(item)" onkeypress="">
                                    <label class="cursor-pointer" style="margin-top: -4px;" for="{{item}}">
                                        {{item.name}} {{item.surname}}
                                    </label>
                                </div>
                            </div>
                            <div>
                                <label class="ast" for="inputLevel"><b>Presenza</b></label>
                                <select class="form-select form-select-lg" aria-label="Default select example"
                                    formControlName="presenza">
                                    <option value="No">No
                                    </option>
                                    <option value="Si">Si
                                    </option>
                                </select>
                            </div>
                            <div>
                                <label for="inputLevel"><b>Note Studente</b></label>
                                <input type="text" class="form-control form-control-lg mb-3"
                                    formControlName="note_studente">
                            </div>
                        </form>
                        <div class="mt-4 d-flex justify-content-between">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Chiudi</button>
                            <button type="button" class="btn color-butt" (click)="addStudent()" data-dismiss="modal"
                                [disabled]="!formAddStudente.valid">Salva</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>


        <!-- ! Modal Edit Studente -->
        <div class="modal fade" id="editStudent" tabindex="-1" aria-labelledby="editStudentLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title" id="exampleModalLabel">Modifica Studente</h1>
                        <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <form [formGroup]="formEditStudente">
                            <div>
                                <label class="ast" for="inputLevel"><b>Presenza</b></label>
                                <select class="form-select form-select-lg" aria-label="Default select example"
                                    formControlName="presenza">
                                    <option value="No">No
                                    </option>
                                    <option value="Si">Si
                                    </option>
                                </select>
                            </div>
                            <div>
                                <label class="ast" for="inputLevel"><b>Note Studente</b></label>
                                <input type="text" class="form-control form-control-lg mb-3"
                                    formControlName="note_studente">
                            </div>
                        </form>
                        <div class="mt-4 d-flex justify-content-between">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Chiudi</button>
                            <button type="button" class="btn color-butt" (click)="editStudente()" data-dismiss="modal"
                                [disabled]="!formEditStudente.valid">Salva</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>


        <!--MODALE ELIMINA STUDENTE-->
        <div class="modal fade bd-example-modal-sm" id="modalDeleteClass" tabindex="-1"
            aria-labelledby="mySmallModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-sm mod-width">
                <div class="modal-content">
                    <div class="modal-header pb-4 border-bottom">
                        <h2 class="my-0">Elimina utente</h2>
                        <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body mt-4">
                        <p class="f-16 mb-3">Eliminare definitivamente</p>
                        <div class="d-flex justify-content-between mt-5">
                            <button class="btn color-butt" data-dismiss="modal">Annulla</button>
                            <button class="btn btn-danger" data-dismiss="modal"
                                (click)="deleteMember(delete_id)">Elimina</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>