<div class="altezza-page px-0 mx-0" id="wrap2">
    <div class="row px-0 mx-0" style="height: 100%;">
        <div class="col-md-2 px-0 mx-0" style="position: relative">
            <aside class="bg-color">
                <div class="margine-sup padding-logo">
                    <a routerLink="/appuntamenti">
                        <img alt="Logo" class="h-25px logo" src="/assets/img/logo-yeschool-1.svg" />
                    </a>
                </div>

                <ng-container [formGroup]="filters">
                    <!-- FILTRO PER DATA -->
                    <div class="menu-item mt-5 padding-logo">
                        <div class="accordion" id="dateAccordion">
                            <div class="accordion-item" style="border:none">
                                <h2 class="accordion-header mb-0" id="dateHeading">
                                        <span class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#dateCollapse" aria-expanded="true" aria-controls="dateCollapse">
                                            Data
                                        </span>
                                </h2>

                                <div id="dateCollapse" class="accordion-collapse collapse show" aria-labelledby="dateHeading" data-bs-parent="#dateAccordion">
                                    <div class="accordion-body">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <input matInput formControlName="date" [matDatepicker]="date" readonly>
                                            <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
                                            <mat-datepicker #date></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- FILTRO PER STRINGA -->
                    <div class="menu-item padding-logo">
                        <div class="accordion" id="stringAccordion">
                            <div class="accordion-item" style="border:none">
                                <h2 class="accordion-header mb-0" id="stringHeading">
                                        <span class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#stringCollapse" aria-expanded="true" aria-controls="stringCollapse">
                                            Ricerca
                                        </span>
                                </h2>

                                <div id="stringCollapse" class="accordion-collapse collapse show" aria-labelledby="stringHeading" data-bs-parent="#stringAccordion">
                                    <div class="accordion-body">
                                        <input type="text" class="form-control" formControlName="string">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <div class="text-center mt-5">
                    <button (click)="filters.reset()" type="button" class="btn btn-primary">
                        RESET FILTRI
                    </button>
                </div>
            </aside>
        </div>
        <div class="col-md-10 margine-sup">
            <div class="section__header">
                <div class="row mb-2 d-flex align-items-center">
                    <div class="col-10">
                        <h1 class="mb-0 pb-0">Comunicazioni</h1>
                    </div>
                </div>
            </div>
            
            <div class="section__body d-flex flex-column">

                <!-- MESSAGGI -->
                <div class="overflow-auto d-flex flex-column-reverse">
                    <div class="container p-0 mt-3">
                        <ng-container *ngFor="let message of messages | async">
                            <div class="d-flex flex-column-reverse" [ngClass]="message?.utente_messaggio?.id == user_id ? 'right-messages' : 'left-messages'">
                                <div class="px-5 py-2 message mb-3"
                                     [ngClass]="{'completed-message': message.flag, 'sent-message': message?.utente_messaggio?.id == user_id}">

                                    <span>{{message?.messaggio}}</span>
                                </div>

                                <div *ngIf="message.edited_at">
                                    <em>Ultima modifica: {{message.edited_at | date:'dd/MM/YYYY HH:mm'}}</em>
                                </div>

                                <div class="mb-1">
                                    <strong class="me-2">
                                        {{message?.utente_messaggio?.utente_anagrafica?.name}}
                                        {{message?.utente_messaggio?.utente_anagrafica?.surname}}
                                    </strong>

                                    <em class="me-2">{{message.created_at | date:'dd/MM/YYYY HH:mm'}}</em>
                                    
                                    <ng-container *ngIf="user_id == message?.utente_messaggio?.id">
                                        <button class="btn p-0 ms-1" (click)="onDeletetMessage(message.id)">
                                            <i style="color: red" class="fa fa-trash" matTooltip="Elimina" matTooltipPosition="above"></i>
                                        </button>

                                        <button class="btn p-0 ms-1" (click)="onEditMessage(message)">
                                            <i style="color: #0d6efd" class="fa fa-edit" matTooltip="Modifica" matTooltipPosition="above"></i>
                                        </button>
                                    </ng-container>

                                    <button class="btn p-0" (click)="editFlag(message.id, !message.flag)">
                                        <i [ngStyle]="{'color': message?.flag ? '#0ec90e' : '#0d6efd'}" class="fa fa-check" [matTooltip]="message?.flag ? 'Segna come non completato' : 'Segna come completato'" matTooltipPosition="above"></i>
                                    </button>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>

                <!-- TEXTAREA -->
                <div class="my-2 w-100">
                    <textarea class="form-control radius" style="resize: none" [formControl]="messageControl" rows="3"></textarea>
                    <button class="btn btn-primary d-block mx-auto mt-3 mb-3" (click)="addMessage()" [disabled]="!messageControl.value">
                        Aggiungi messaggio
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #editModal>
    <div class="modal-header mb-4 ps-0">
        <h2 class="m-0 p-0">Modifica messaggio</h2>
        <button type="button" class="btn-close" (click)="dialog.closeAll()"></button>
    </div>

    <div class="d-flex flex-column h-100 justify-content-between">
        <div [formGroup]="messageForm">
            <textarea rows="5" class="form-control" formControlName="message" style="resize: none"></textarea>
        </div>

        <div class="text-end mt-3">
            <button type="button" class="btn btn-custom" (click)="editMessage()">
                MODIFICA
            </button>
        </div>
    </div>
</ng-template>

<ng-template #confirmModal>
    <div class="modal-header mb-4 ps-0">
        <h2 class="m-0 p-0">Elimina messaggio</h2>
        <button type="button" class="btn-close" (click)="dialog.closeAll()"></button>
    </div>
    
    <div class="d-flex flex-column h-100 justify-content-between">
        <p>Sei sicuro di voler procedere?</p>

        <div class="text-end mt-3">
            <button type="button" class="btn btn-danger" (click)="deleteMessage()">
                ELIMINA
            </button>
        </div>
    </div>
</ng-template>