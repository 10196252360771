<div class="altezza-page px-0 mx-0" id="wrap2">
    <div class="row px-0 mx-0">
        <div class="col-md-2 px-0 mx-0">
            <aside class="bg-color">
                <div class="margine-sup-side padding-logo">
                    <a routerLink="/appuntamenti">
                        <img class="h-25px logo" src="./assets/img/logo-yeschool-1.svg" alt="logo-yeschool-1">
                    </a>
                </div>
                <div class="menu-item padding-logo">
                    <div class="menu-item padding-logo">
                        <h4 class="section__tab section_text"> Contabilità </h4>
                    </div>
                    <div class="menu-item ">
                        <div class="row">
                            <div class="col-11">
                                <div class="accordion" id="accordionExample">
                                    <div class="accordion-item" style="border:none">
                                        <h2 class="accordion-header" id="headingOne">
                                            <span class="accordion-button accordion-custom-style" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                                aria-expanded="true" aria-controls="collapseOne">
                                                Filtra per date
                                            </span>
                                        </h2>
                                        <div id="collapseOne" class="accordion-collapse collapse show"
                                            aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <ng-container>
                                                    <div class="col-12 mt-1 mb-2">

                                                        <form [formGroup]="dateForm">
                                                            <div class="form-group">
                                                                <label for="inputSurname"><b>Data inizio</b></label>
                                                                <mat-form-field appearance="outline"
                                                                    class="col-12 no_padding">
                                                                    <input matInput [matDatepicker]="data_inizio"
                                                                        formControlName="data_inizio"
                                                                        (dateChange)="onStartDateChange()">
                                                                    <mat-datepicker-toggle matSuffix
                                                                        [for]="data_inizio"></mat-datepicker-toggle>
                                                                    <mat-datepicker #data_inizio></mat-datepicker>
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="form-group mt-2">
                                                                <label for="inputSurname"><b>Data fine</b></label>
                                                                <mat-form-field appearance="outline"
                                                                    class="col-12 no_padding">
                                                                    <input matInput [matDatepicker]="data_fine"
                                                                        formControlName="data_fine" [min]="minEndDate">
                                                                    <mat-datepicker-toggle matSuffix
                                                                        [for]="data_fine"></mat-datepicker-toggle>
                                                                    <mat-datepicker #data_fine></mat-datepicker>
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="d-flex justify-content-between">
                                                                <button type="button" (click)="resetDate()"
                                                                    class="btn btn-danger my-3 px-2 py-1"
                                                                    data-dismiss="modal">Reset</button>
                                                                <button type="button" (click)="setDate()"
                                                                    class="btn color-butt my-3 px-2 py-1"
                                                                    data-dismiss="modal">Filtra date</button>
                                                            </div>
                                                        </form>

                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion">
                                    <div class="accordione-item" id="status-accordion">

                                        <div class="accordion-header" id="headingOne">
                                            <span class="accordion-button accordion-custom-style" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#status-collapse"
                                                aria-expanded="true" aria-controls="status-collapse">
                                                Filtra per stato
                                            </span>
                                        </div>

                                    </div>

                                    <div id="status-collapse" class="accordion-collapse collapse show"
                                        aria-labelledby="headingOne" data-bs-parent="#status-accordion">
                                        <div class="accordion-body">
                                            <form [formGroup]="statusForm">
                                                <select class="form-select form-select-lg status-filter-select"
                                                    aria-label="inputflag" formControlName="status">
                                                    <option *ngFor="let status of statusOptions" [value]="status.value">
                                                        {{status.label}}</option>
                                                </select>
                                            </form>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </aside>
        </div>
        <div class="col-md-10 margine-sup">

            <div class="section__header">
                <div class="row mb-2 d-flex align-items-center">
                    <div class="col-8">
                        <h1 class="mb-0 pb-0">Contabilità</h1>
                    </div>
                    <div class="col-4 d-flex justify-content-end"></div>
                </div>
            </div>

            <div class="section__body">
                <div class="row mt-4"></div>
                <!--SEZIONE TAB-->
                <nav>
                    <div class="nav nav-tabs d-flex justify-content-between align-items-start" id="nav-tab"
                        role="tablist">
                        <div class="d-flex">
                            <button *ngFor="let button of buttonList" type="button" class="nav-link"
                                [class]="button.flag == flagFilter ? 'active' : null"
                                (click)="setSection(button)">{{button.value}}</button>
                        </div>

                        <div class="d-flex">
                            <div class="d-flex total-round" [class]="flagFilter == null ? 'me-2' : '' ">
                                <div [class]="flagFilter == null ? 'me-4' : '' "
                                    *ngIf="flagFilter == 0 || flagFilter == null"><span
                                        class="total-title total-in-label">Entrate:</span> {{
                                    formatNumber(
                                    totaleEntrate) }}
                                </div>
                                <div *ngIf="flagFilter == 1 || flagFilter == null"><span
                                        class="total-title total-out-label">Uscite:</span> {{
                                    formatNumber(totaleUscite) }}</div>
                            </div>

                            <div *ngIf="flagFilter == null" class="total-round"><span class="total-title">Totale:</span>
                                {{
                                formatNumber(differenzaTotali) }}</div>
                        </div>

                    </div>
                </nav>

                <!--FINE SEZIONE TAB-->
                <div class="tab-content" id="nav-tabContent">
                    <!--TAB TUTTI-->
                    <div class="tab-pane fade show active" id="nav-all" role="tabpanel" aria-labelledby="nav-all-tab">

                        <h1 class="my-2">{{sectionName}}</h1>

                        <div class="row my-4 justify-content-between">
                            <div class="col-4 d-flex align-items-center">
                                <input placeholder="Cerca per intestazione, studente o ricevuta" [(ngModel)]="filter"
                                    (keyup)="setTableSearchValue($event)"
                                    class="form-control form-control-lg radiusSelect">
                            </div>
                            <div class="col-2 d-flex justify-content-end">
                                <button type="button" class="btn color-butt my-2 add-transaction-btn"
                                    data-toggle="modal" data-target="#addInput" (click)="openModal()">
                                    Aggiungi transazione
                                </button>
                            </div>
                        </div>

                        <div *ngIf="(records | async) == 0">
                            <div *ngIf="!sectionLoading" class="no-cont py-4"><i class="f-16">Nessun risultato
                                    riscontrato</i> </div>

                            <div *ngIf="sectionLoading" class="d-flex justify-content-center no-cont py-4">
                                <output class="spinner-border table-load-spinner"></output>
                            </div>
                        </div>

                        <div *ngIf="(records | async) > 0">
                            <p class="mb-3"><b>Legenda stati</b></p>
                            <ul>
                                <li><i class="bi bi-plus-circle-fill text-success"></i> Entrata, stato saldato
                                <li><i class="bi-plus-circle-fill text-warning"></i> Entrata, stato non saldato
                                <li><i class="bi bi-dash-circle-fill text-danger"></i> Uscita
                            </ul>
                        </div>

                        <table *ngIf="(records | async) > 0" mat-table [dataSource]="(entrate|async)"
                            class="tb-bord transaction-table">
                            <!-- Intestazione Column -->
                            <ng-container matColumnDef="intestazione">
                                <th class="py-3 colore-riga testo-riga" mat-header-cell *matHeaderCellDef> Intestazione
                                </th>
                                <td mat-cell *matCellDef="let element">{{element.intestazione.length > 80 ?
                                    (element.intestazione | slice:0:80) + '...' : element.intestazione}}</td>
                            </ng-container>

                            <!-- Data Column -->
                            <ng-container matColumnDef="data">
                                <th class="py-3 colore-riga testo-riga" mat-header-cell *matHeaderCellDef> Data </th>
                                <td mat-cell *matCellDef="let element">{{element.data | date:'dd/MM/YYYY'}}</td>
                            </ng-container>

                             <!-- Utente Column -->
                             <ng-container matColumnDef="utente">
                                <th class="py-3 colore-riga testo-riga" mat-header-cell *matHeaderCellDef> Utente </th>
                                <td mat-cell *matCellDef="let element">{{element.elenco_anagrafica?.name}} {{element.elenco_anagrafica?.surname}}</td>
                            </ng-container>

                            <ng-container matColumnDef="ricevuta">
                                <th class="py-3 colore-riga testo-riga" mat-header-cell *matHeaderCellDef> Ricevuta
                                </th>
                                <td mat-cell *matCellDef="let element">{{element.ricevuta ?? '-'}}</td>
                            </ng-container>

                            <ng-container matColumnDef="oggetto">
                                <th class="py-3 colore-riga testo-riga" mat-header-cell *matHeaderCellDef> Oggetto
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.oggetto}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="importo">
                                <th class="py-3 colore-riga testo-riga" mat-header-cell *matHeaderCellDef>Importo</th>
                                <td mat-cell *matCellDef="let element">
                                    <i *ngIf="element.flag != null" class="bi"
                                        [class]="!element.flag ? (element.stato=='Saldato' ? 'bi-plus-circle-fill text-success' : 'bi-plus-circle-fill text-warning') : 'bi-dash-circle-fill text-danger' "></i>
                                    {{formatNumber(element.importo)}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="mezzo">
                                <th class="py-3 colore-riga testo-riga" mat-header-cell *matHeaderCellDef> Mezzo
                                </th>
                                <td mat-cell *matCellDef="let element">{{element.mezzo ?? '-'}}</td>
                            </ng-container>

                            <!-- Azione Column -->
                            <ng-container matColumnDef="azione">
                                <th class="py-3 colore-riga testo-riga" mat-header-cell *matHeaderCellDef>
                                    <span>Azione</span>
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <button class="btn btn-outline-primary" data-toggle="dropdown">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
                                            <path
                                                d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                                        </svg>
                                    </button>

                                    <div class="dropdown-menu">
                                        <a class="dropdown-item cursor-pointer" data-toggle="modal" data-target="#viewData" (click)="setStudente(element)">Visualizza info</a>
                                        <a *ngIf="element.stato != 'Saldato'" class="dropdown-item cursor-pointer" data-toggle="modal" data-target="#addInput" (click)="editTransazione(element)">Modifica</a>
                                        <a class="dropdown-item cursor-pointer" data-toggle="modal" data-target="#deleteInput" (click)="setDeleteEntrata(element)">Elimina</a>
                                    </div>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" style="text-align: center;"
                                    [attr.colspan]="displayedColumns.length">Nessun elemento trovato
                                </td>
                            </tr>
                        </table>

                        <mat-paginator [length]="records | async" [pageSize]="pageSize"
                            [pageSizeOptions]="[25, 50, 100, 200]" (page)="pageEvent = handlePage($event)"
                            showFirstLastButtons>
                        </mat-paginator>
                    </div>

                    <!--TAB ENTRATE-->
                    <div class="tab-pane fade" id="nav-input" role="tabpanel" aria-labelledby="nav-input-tab">


                    </div>

                    <!--TAB USCITE-->
                    <div class="tab-pane fade" id="nav-output" role="tabpanel" aria-labelledby="nav-output-tab">


                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- ! Modal View Member -->
<div class="modal fade" id="viewData" tabindex="-1" aria-labelledby="viewDataLabel" aria-hidden="true">
    <div class="modal-dialog modal-sm">

        <div class="modal-content">
            <div class="modal-header pb-4 border-bottom">
                <div class="d-flex align-items-center">
                    <h2 class="modal-title" id="viewMemberLabel">Dettaglio
                        {{studente_settato?.flag == 0 ? '- Entrata' : studente_settato?.flag == 1 ? ' - Uscita' : null}}
                    </h2>
                </div>
                <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>

            <div class="modal-body">

                <!--DETTAGLIO RATA-->
                <div class="row mt-3">
                    <div class="col-6">
                        <div class="detail-rata-box">
                            <span class="detail-rata-title">Data:</span>
                            <span>
                                {{studente_settato?.data|date:'dd/MM/yyyy'}}
                            </span>
                        </div>
                    </div>

                    <div class="col-6">
                        <div class="detail-rata-box">
                            <span class="detail-rata-title">Importo:</span>
                            <span>
                                {{ formatNumber(studente_settato?.importo)}}
                            </span>
                        </div>
                    </div>
                </div>

                <div class="row mt-4">

                    <div class="col-6">
                        <div class="detail-rata-box">
                            <span class="detail-rata-title">Ricevuta:</span>
                            <span [class]="!studente_settato?.ricevuta ? 'title-text-italic': null">
                                {{studente_settato?.ricevuta ?
                                studente_settato?.ricevuta : 'nessun dato'}}
                            </span>
                        </div>
                    </div>

                    <div class="col-6">
                        <div class="detail-rata-box">
                            <span class="detail-rata-title">Mezzo:</span>
                            <span [class]="!studente_settato?.mezzo ? 'title-text-italic': null">
                                {{studente_settato?.mezzo ?
                                studente_settato?.mezzo : 'nessun dato'}}
                            </span>
                        </div>
                    </div>
                </div>

                <div class="row mt-4">
                    <div class="col-6">
                        <div class="detail-rata-box">
                            <span class="detail-rata-title">Intestazione:</span>
                            <span class="w-break">
                                {{studente_settato?.intestazione}}
                            </span>
                        </div>
                    </div>

                    <div class="col-6">
                        <div class="detail-rata-box">
                            <span class="detail-rata-title">Oggetto:</span>
                            <span class="w-break" [class]="!studente_settato?.oggetto ? 'title-text-italic': null">
                                {{studente_settato?.oggetto ?
                                studente_settato?.oggetto : 'nessun dato'}}
                            </span>
                        </div>
                    </div>
                </div>

                <div class="row mt-4">
                    <div class="col-6">
                        <div class="detail-rata-box">
                            <span class="detail-rata-title">IVA:</span>
                            <span [class]="!studente_settato?.iva ? 'title-text-italic': null">
                                {{ studente_settato?.iva ? formatNumber(studente_settato?.iva) : 'nessun dato' }}
                            </span>
                        </div>
                    </div>

                </div>

                <!-- Dati anagrafici studente -->
                <div *ngIf="studente_settato?.elenco_anagrafica!=null" class="mt-5">
                    <h3 class="student-title-section">Dettaglio Studente</h3>

                    <div class="row mt-3">
                        <div class="col-6">
                            <div class="detail-rata-box">
                                <span class="detail-rata-title">Nome:</span>
                                <span [class]="!studente_settato?.elenco_anagrafica?.name ? 'title-text-italic': null">
                                    {{ studente_settato?.elenco_anagrafica?.name ?
                                    studente_settato?.elenco_anagrafica?.name : 'nessun dato'}}
                                </span>
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="detail-rata-box">
                                <span class="detail-rata-title">Cognome:</span>
                                <span
                                    [class]="!studente_settato?.elenco_anagrafica?.surname ? 'title-text-italic': null">
                                    {{ studente_settato?.elenco_anagrafica?.surname ?
                                    studente_settato?.elenco_anagrafica?.surname : 'nessun dato'}}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-3">

                        <div class="col-6">
                            <div class="detail-rata-box">
                                <span class="detail-rata-title">Email:</span>
                                <span [class]="!studente_settato?.elenco_anagrafica?.email ? 'title-text-italic': null">
                                    {{ studente_settato?.elenco_anagrafica?.email ?
                                    studente_settato?.elenco_anagrafica?.email : 'nessun dato'}}
                                </span>
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="detail-rata-box">
                                <span class="detail-rata-title">Telefono:</span>
                                <span [class]="!studente_settato?.elenco_anagrafica?.phone ? 'title-text-italic': null">
                                    {{ studente_settato?.elenco_anagrafica?.phone ?
                                    studente_settato?.elenco_anagrafica?.phone : 'nessun dato'}}
                                </span>
                            </div>
                        </div>

                    </div>
                    <div class="row mt-3">

                        <div class="col-12">
                            <div class="detail-rata-box">
                                <span class="detail-rata-title">Data di nascita:</span>
                                <span
                                    [class]="!studente_settato?.elenco_anagrafica?.birth_date ? 'title-text-italic': null">
                                    {{ studente_settato?.elenco_anagrafica?.birth_date ?
                                    (studente_settato?.elenco_anagrafica?.birth_date |
                                    date:'dd/MM/YYYY') : 'nessun dato'}}
                                </span>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="mt-5">
                    <button type="button" class="btn btn-danger" data-dismiss="modal">Chiudi</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- ! Modal Add Input  -->
<div class="modal text-left fade" id="addInput" tabindex="-1" aria-labelledby="addInputModal" aria-hidden="true">
    <div class="modal-dialog modal-xxl">
        <div class="modal-content">
            <div class="modal-header pb-4 border-bottom">

                <h2>{{isEdit ? 'Modifica' : 'Aggiungi'}} transazione</h2>
                <button type="button" (click)="resetModal()" class="btn-close" data-dismiss="modal"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="inputAddForm">
                    <h3> Dati </h3>
                    <div class="row">
                        <div class="col-4">
                            <div class="form-group mb-3">
                                <label class="ast" for="inputflag">Entrata / Uscita</label>
                                <select class="form-select form-select-lg" aria-label="inputflag"
                                    formControlName="flag">
                                    <option *ngFor="let value of inOutFlag" value="{{value.valore}}">{{value.label}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <div class="form-group mb-3">
                                <label class="ast" for="inputIntestazione"><b>Intestazione</b></label>
                                <input type="text" class="form-control form-control-lg" formControlName="intestazione">
                            </div>
                        </div>
                        <div class="col-4">

                            <label class="ast" for="inputName">Utente</label>

                            <input type="text" class="form-control form-control-lg mb-3 form-control_modal search"
                                placeholder="Cerca per nome o cognome" (keyup)="searchAll($event)"
                                formControlName="search_member">

                            <div class="container" *ngIf="visualizzaTutti" class="boxautocomplete">

                                <div *ngIf="!noResult; else noResultMessage">
                                    <div class="row element-autocomplete mx-0 px-0 cursor-pointer boxautocomplete-result"
                                        *ngFor="let item of (tutti | async)" (click)="setMember(item)" onkeydown="">
                                        <label class="cursor-pointer" style="margin-top: -4px;" for="{{item}}">
                                            {{item.name}} {{item.surname}}
                                        </label>
                                    </div>
                                </div>

                                <ng-template #noResultMessage>
                                    <div class="boxautocomplete-result">Nessun
                                        risultato</div>
                                </ng-template>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="form-group">
                                <label class="ast" for="inputSurname"><b>Data</b></label>
                                <mat-form-field appearance="outline" class="col-12 no_padding date-picker-style">
                                    <input matInput readonly [matDatepicker]="data" formControlName="data">
                                    <mat-datepicker-toggle matSuffix [for]="data">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #data></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-4">
                            <div class="form-group mb-3">
                                <label for="inputRicevuta"><b>Ricevuta</b></label>
                                <input type="text" class="form-control form-control-lg" formControlName="ricevuta">
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="form-group mb-3">
                                <label for="inputEmail"><b>Oggetto</b></label>
                                <input type="text" class="form-control form-control-lg" formControlName="oggetto">
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="form-group mb-3">
                                <label class="ast" for="inputEmail"><b>Importo</b></label>
                                <input type="number" class="form-control form-control-lg" formControlName="importo">
                            </div>
                        </div>

                    </div>

                    <div class="row">
                        <div class="col-4">
                            <div class="form-group mb-3">
                                <label for="mezzo-field">Mezzo</label>
                                <input id="mezzo-field" type="text" class="form-control form-control-lg"
                                    formControlName="mezzo">
                            </div>
                        </div>

                        <div class="col-4">
                            <div class="form-group mb-3">
                                <label for="iva-field">IVA</label>
                                <input id="iva-field" type="number" class="form-control form-control-lg"
                                    formControlName="iva">
                            </div>
                        </div>

                        <div class="col-4">
                            <div class="form-group mb-3">
                                <label class="ast" for="inputMezzo"><b>Stato</b></label>
                                <select class="form-select form-select-lg" aria-label="inputflag"
                                    formControlName="stato">
                                    <option *ngFor="let value of stati" value="{{value.id}}">{{value.label}}</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <p><i>I campi contrassegnati <span style="color: red">*</span> sono obbligatori</i></p>
                    <div class="d-flex justify-content-end">
                        <div class="pt-4">
                            <button type="button" [disabled]="!inputAddForm.valid"
                                (click)="isEdit ? saveEditEntrata() : addEntrata()" class=" btn color-butt my-3"
                                data-dismiss="modal">
                                Salva
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- ! Modal Delete Input  -->
<div class="modal fade bd-example-modal-sm" id="deleteInput" tabindex="-1" aria-labelledby="deleteInputLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-sm mod-width">
        <div class="modal-content">
            <div class="modal-header pb-4 border-bottom">
                <h2 class="my-0">Elimina transazione</h2>
                <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body mt-4">
                <p class="f-16 mb-3 delete-transaction-warning-title">Eliminare definitivamente la transazione <span
                        class="transaction-delete-title">"{{deleteContent?.intestazione}}"</span>?</p>
                <div class="d-flex justify-content-between mt-5">
                    <button class="btn color-butt" data-dismiss="modal">Annulla</button>
                    <button class="btn btn-danger" data-dismiss="modal"
                        (click)="deleteEntrata(deleteContent?.id)">Elimina</button>
                </div>
            </div>
        </div>
    </div>
</div>